/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Roboto", Arial,  sans-serif;
}

h2 {
  font-family: "Roboto", Arial,  sans-serif;
}

.generalButton {
  background: #3F51B5;
  border-radius: 1cm;
   border: none;
  outline: none;
  color: white;
  font-family: "Roboto", Arial,  sans-serif;
  font-size: calc(0.35cm + 0.15vw + 0.15vh);
  font-weight: 470;
  height: calc(1cm + 1.5vh);

}

.redButton {
  background: linear-gradient(
    116deg,
    rgba(255, 0, 0, 0.664) 30%,
    rgba(255, 28, 28, 0.575) 100%
  );
  border-radius: 0.5cm;
  outline: none;
  color: rgb(255, 255, 255);
  font-family: "Roboto", Arial,  sans-serif;
  font-size: calc(0.35cm + 0.1vw + 0.1vh);
  font-weight: bold;
}

.greenButton {
  background: linear-gradient(
    116deg,
    rgba(21, 255, 0, 0.733) 30%,
    rgba(50, 255, 32, 0.479) 100%
  );
  border-radius: 0.5cm;
  outline: none;
  color: rgb(17, 17, 17);
  font-family: "Roboto", Arial,  sans-serif;
  font-size: calc(0.35cm + 0.1vw + 0.1vh);
  font-weight: bold;
}



.fade {
  display: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 50;
}

.transition {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 105, 180, 0.418);
  z-index: 50;
}

.greyButton {
  background: linear-gradient(
    116deg,
    rgba(146, 146, 146, 0.856) 30%,
    rgb(146, 146, 146) 100%
  );
  border-radius: 0.5cm;
  outline: none;
  color: white;
  font-family: "Roboto", Arial,  sans-serif;
  font-size: calc(0.35cm + 0.1vw + 0.1vh);
}

p {
  font-family: 'Google Sans',Roboto,Arial,sans-serif;
}

.fontMullish {
  font-family: 'Google Sans',Roboto,Arial,sans-serif;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(240, 248, 255, 0);
  box-shadow: inset 0 0 3px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3F51B5; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3F51B5; 
}